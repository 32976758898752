import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IUseFormProps } from '../../app/hooks/useFormProps';

import { Benefits } from '../../sections/Benefits';
import { Contacts } from '../../sections/Contacts';
import { FAQS } from '../../sections/FAQS';
import { Footer } from '../../sections/Footer';
import { Hero } from '../../sections/Hero';
import { HowItWork } from '../../sections/HowItWork';

interface IMainPageProps
    extends Omit<IUseFormProps, 'isSubmitted' | 'onHandleClearData'> {
    className?: string;
    loaded: boolean;
}

export function MainPage(props: IMainPageProps) {
    const { i18n } = useTranslation();
    const isRtlDirection = i18n.language === 'ab' || i18n.language === 'fa';
    const howItWorkRef = useRef(null);
    const benefitsRef = useRef(null);
    const faqsRef = useRef(null);
    const contactsRef = useRef(null);

    return (
        <>
            <Hero
                rtlDirection={isRtlDirection}
                howItWorkRef={howItWorkRef}
                benefitsRef={benefitsRef}
                faqsRef={faqsRef}
                contactsRef={contactsRef}
                {...props}
            />
            {props.loaded && (
                <>
                    <div ref={howItWorkRef}>
                        <HowItWork rtlDirection={isRtlDirection} />
                    </div>
                    <div ref={benefitsRef}>
                        <Benefits rtlDirection={isRtlDirection} />
                    </div>
                    <div ref={faqsRef}>
                        <FAQS rtlDirection={isRtlDirection} />
                    </div>
                    <div ref={contactsRef}>
                        <Contacts rtlDirection={isRtlDirection} />
                    </div>
                    <Footer rtlDirection={isRtlDirection} />
                </>
            )}
        </>
    );
}
