import { useTranslation } from 'react-i18next';

import { Footer } from '../../sections/Footer';
import { Result } from '../../sections/Result';
import { IResponseData } from '../../shared/types';

import s from './ResultPage.module.scss';

interface IResultPage {
    data: IResponseData;
    handleClickBack: () => void;
}

export function ResultPage({ data, handleClickBack }: IResultPage) {
    const { i18n } = useTranslation();
    const isRtlDirection = i18n.language === 'ab' || i18n.language === 'fa';

    return (
        <div className={s.container}>
            <Result
                handleClickBack={handleClickBack}
                data={data}
                rtlDirection={isRtlDirection}
            />
            <Footer rtlDirection={isRtlDirection} />
        </div>
    );
}
