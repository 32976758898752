import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { BlockSection } from '../../shared/ui/BlockSection';
import { IWrapperProps } from '../../shared/ui/SectionTitle/types';
import { BENEFITS_I18_MAP } from './consts';

import s from './Benefits.module.scss';

interface IBenefitsComponent extends IWrapperProps {
    classname?: string;
}

const BenefitsComponent = (props: IBenefitsComponent) => {
    const { classname, rtlDirection } = props;
    const { t } = useTranslation();

    return (
        <ul
            className={cn(s.container, classname, {
                [s.rtlDirection]: rtlDirection,
            })}
        >
            {BENEFITS_I18_MAP.map(({ question, answer }, index) => (
                <li className={s.listItem} key={question}>
                    <h3 className={s.question}>{t(question)}</h3>
                    <p className={s.answer}>{t(answer)}</p>
                    <label className={s.number}>{++index}</label>
                </li>
            ))}
        </ul>
    );
};

const BenefitsWithWrapper = ({ rtlDirection }: IWrapperProps) => {
    return (
        <BlockSection
            rtlDirection={rtlDirection}
            contentClassname={s.wrapper}
            theme={'BLACK'}
            title={'BENEFITS OF USING MIXER'}
        >
            <BenefitsComponent rtlDirection={rtlDirection} />
        </BlockSection>
    );
};

export const Benefits = memo(BenefitsWithWrapper);
