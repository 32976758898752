import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { BlockSection } from 'shared/ui/BlockSection';

import { CLEARNET_URL, TOR_NETWORK_URL } from '../../shared/consts';
import { IWrapperProps } from '../../shared/ui/SectionTitle/types';
import { ReactComponent as Image } from './assets/circle_white.svg';

import s from './Footer.module.scss';

interface IFooterComponent extends IWrapperProps {
    classname?: string;
}

const FooterComponent = (props: IFooterComponent) => {
    const { classname, rtlDirection } = props;
    const { t } = useTranslation();

    return (
        <div
            className={cn(s.container, classname, {
                [s.rtlDirection]: rtlDirection,
            })}
        >
            <Image className={s.image} />
            <div className={s.links}>
                <a className={cn(s.anchor, s.link)} href={TOR_NETWORK_URL}>
                    {t('Tor network version')}
                </a>
                <a className={cn(s.anchor, s.link)} href={CLEARNET_URL}>
                    {t('Clearnet version')}
                </a>
            </div>
            <div className={s.info}>
                <h5 className={s.subtitle}>{t('PGP fingerprint')}</h5>
                <a className={cn(s.anchor, s.value)} href={'#'}>
                    {t('0ED1B0CAE84D87EFB9A7BD38A33A1DD44BA77E16')}
                </a>
                <p className={s.text}>
                    {t(
                        'Follow the link to download a public key to verify the letters of guarantee provided by the platform.',
                    )}
                </p>
            </div>
        </div>
    );
};

const FooterWithWrapper = ({ rtlDirection }: IWrapperProps) => {
    return (
        <BlockSection
            titleClassname={s.title}
            theme={'BLACK'}
            title={'MIXTURA'}
            rtlDirection={rtlDirection}
        >
            <FooterComponent rtlDirection={rtlDirection} />
        </BlockSection>
    );
};

export const Footer = memo(FooterWithWrapper);
