import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ReactComponent as Asia } from 'shared/assets/images/asia.svg';
import bitcoin from 'shared/assets/images/bitcoinArrow.png';
import circle from 'shared/assets/images/circle.png';
import { ReactComponent as Europe } from 'shared/assets/images/europe.svg';
import { ReactComponent as North } from 'shared/assets/images/north.svg';
import smile from 'shared/assets/images/smile.png';
import { BlockSection } from 'shared/ui/BlockSection';
import { IWrapperProps } from 'shared/ui/SectionTitle/types';

import {
    arrowCenterBase64,
    arrowFromBottomBase64,
    arrowFromTopBase64,
} from '../../shared/assets/base64';
import { HOWITWORK_I18_MAP } from './consts';

import s from './HowItWork.module.scss';

interface IHowItWorkComponent extends IWrapperProps {
    classname?: string;
}

const HowItWorkComponent = (props: IHowItWorkComponent) => {
    const { classname, rtlDirection } = props;
    const { t } = useTranslation();

    return (
        <div
            className={cn(s.container, classname, {
                [s.rtlDirection]: rtlDirection,
            })}
        >
            <div className={s.portrait}>
                <div className={s.mainImageWrapper}>
                    <img className={s.mainImage} src={circle} />
                    <div className={s.title}>
                        {t(
                            'Mixtura mixes coins through the chain of stock exhanges on three continents',
                        )
                            .split('#br')
                            .map((line) => (
                                <p key={line}>
                                    {line}
                                    <br />
                                </p>
                            ))}
                    </div>
                </div>
                <div className={s.secondImageWrapper}>
                    <img className={s.bitcoinImage} src={bitcoin} />
                </div>
                <div className={s.thirdImageWrapper}>
                    <img className={s.smileImage} src={smile} />
                </div>
                <div className={s.countriesImageWrapper}>
                    <div className={s.countryWrapper}>
                        <Europe className={s.countryImage} />
                        <label className={s.countryLabel}>{t('Europe')}</label>
                        <img
                            className={cn(s.arrow, s.arrow1)}
                            src={arrowFromTopBase64}
                        />
                    </div>
                    <div className={s.countryWrapper}>
                        <Asia className={s.countryImage} />
                        <label className={s.countryLabel}>{t('Europe')}</label>
                        <img
                            className={cn(s.arrow, s.arrow2)}
                            src={arrowCenterBase64}
                        />
                    </div>
                    <div className={s.countryWrapper}>
                        <North className={s.countryImage} />
                        <label className={s.countryLabel}>
                            {t('North America')}
                        </label>
                        <img
                            className={cn(s.arrow, s.arrow3)}
                            src={arrowFromBottomBase64}
                        />
                    </div>
                </div>
            </div>
            <div className={s.text}>
                {HOWITWORK_I18_MAP.map((line) => (
                    <p key={line} className={s.line}>
                        {t(line)}
                    </p>
                ))}
            </div>
        </div>
    );
};

const HowItWorkWithWrapper = ({ rtlDirection }: IWrapperProps) => {
    return (
        <BlockSection
            rtlDirection={rtlDirection}
            theme={'GRAY'}
            title={'HOW DOES IT WORK?'}
        >
            <HowItWorkComponent rtlDirection={rtlDirection} />
        </BlockSection>
    );
};

export const HowItWork = memo(HowItWorkWithWrapper);
