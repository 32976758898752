import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { BlockSection } from 'shared/ui/BlockSection';

import emailIcon from '../../shared/assets/icons/email.svg';
import telegramIcon from '../../shared/assets/icons/telegram.svg';
import { ReactComponent as Circle } from '../../shared/assets/images/circle3.svg';
import { EMAIL_URL, TELEGRAM_URL } from '../../shared/consts';
import { IWrapperProps } from '../../shared/ui/SectionTitle/types';

import s from './Contacts.module.scss';

interface IContactsComponent extends IWrapperProps {
    classname?: string;
}

const ContactsComponent = (props: IContactsComponent) => {
    const { classname, rtlDirection } = props;
    const { t } = useTranslation();

    return (
        <div
            className={cn(s.container, classname, {
                [s.rtlDirection]: rtlDirection,
            })}
        >
            <a
                href={TELEGRAM_URL}
                target='_blank'
                className={s.button}
                rel='noreferrer'
            >
                <div className={s.iconWrapper}>
                    <img src={telegramIcon} className={s.icon} />
                </div>
                <label className={s.label}>{t('Telegram')}</label>
            </a>
            <a
                href={EMAIL_URL}
                target='_blank'
                rel='noreferrer'
                className={s.button}
            >
                <div className={s.iconWrapper}>
                    <img src={emailIcon} className={s.icon} />
                </div>
                <label className={s.label}>{t('E-mail')}</label>
            </a>
        </div>
    );
};

const ContactsWithWrapper = ({ rtlDirection }: IWrapperProps) => {
    return (
        <BlockSection
            rtlDirection={rtlDirection}
            classname={s.wrapper}
            theme={'GRAY'}
            title={'CONTACTS'}
        >
            <ContactsComponent rtlDirection={rtlDirection} />
            <Circle
                className={cn(s.circle, {
                    [s.circleLeft]: rtlDirection,
                })}
            />
        </BlockSection>
    );
};

export const Contacts = memo(ContactsWithWrapper);
