import { ChangeEvent, InputHTMLAttributes, memo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import s from './Input.module.scss';

type HTMLInputProps = Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'value' | 'onChange'
>;

interface IInputProps extends HTMLInputProps {
    value?: string | number;
    onChange?: (value: string) => void;
    className?: string;
}

const InputComponent = (props: IInputProps) => {
    const {
        className,
        type = 'text',
        value,
        onChange,
        placeholder,
        ...rest
    } = props;

    const { t } = useTranslation();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        onChange?.(value);
    };

    return (
        <input
            className={cn(s.input, className)}
            type={type}
            value={value}
            placeholder={placeholder ? (t(placeholder) as string) : ''}
            onChange={handleChange}
            {...rest}
        />
    );
};

export const Input = memo(InputComponent);
