import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import s from './LangSwitcher.module.scss';

const LangSwitcherComponent = () => {
    const { t, i18n } = useTranslation();

    const handleChangeEn = () => {
        i18n.changeLanguage('en');
    };

    const handleChangeAb = () => {
        i18n.changeLanguage('ab');
    };

    const handleChangeFa = () => {
        i18n.changeLanguage('fa');
    };
    return (
        <div className={s.wrapper}>
            <button
                onClick={handleChangeEn}
                className={cn(s.switcher, {
                    [s.active]: i18n.language === 'en',
                })}
            >
                {t('English')}
            </button>
            <button
                onClick={handleChangeAb}
                className={cn(s.switcher, {
                    [s.active]: i18n.language === 'ab',
                })}
            >
                {t('العربية')}
            </button>
            <button
                onClick={handleChangeFa}
                className={cn(s.switcher, {
                    [s.active]: i18n.language === 'fa',
                })}
            >
                {t('زبان فارسی')}
            </button>
        </div>
    );
};

export const LangSwitcher = memo(LangSwitcherComponent);
