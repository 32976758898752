import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';

import { api } from '../../shared/api/instance';
import { IResponseData } from '../../shared/types';

export interface IUseFormProps {
    onHandleSubmit: (forward_addr: string, forward_addr2: string) => void;
    onHandleClearData: () => void;
    isSubmitted: IResponseData | null;
    handleChangeFirstAddress: (e: string) => void;
    handleChangeSecondAddress: (e: string) => void;
    firstAddress: string;
    secondAddress: string;
    error: string;
    isLoading: boolean;
}

export const useFormProps = (): IUseFormProps => {
    const [isSubmitted, setIsSubmitted] = useState<IResponseData | null>(null);
    const [firstAddress, setFirstAddress] = useState('');
    const [secondAddress, setSecondAddress] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeFirstAddress = useCallback((e: string) => {
        setFirstAddress(() => e);
    }, []);

    const handleChangeSecondAddress = useCallback((e: string) => {
        setSecondAddress(() => e);
    }, []);

    const onHandleSubmit = async (
        forward_addr: string,
        forward_addr2: string,
    ) => {
        try {
            setIsLoading(true);
            setError(() => '');
            if (!forward_addr) {
                setIsLoading(false);
                return setError(() => 'Address is required');
            }

            const { data } = await api.get('/token');

            const response = await api.post('/mix', {
                forward_addr,
                forward_addr2,
                token: data.token,
            });

            setIsSubmitted(response.data);
        } catch (err) {
            setError(handleError(err));
        } finally {
            setIsLoading(false);
        }
    };

    const onHandleClearData = () => {
        setIsSubmitted(() => null);
        setSecondAddress(() => '');
        setFirstAddress(() => '');
    };

    return {
        onHandleSubmit,
        onHandleClearData,
        isSubmitted,
        handleChangeFirstAddress,
        handleChangeSecondAddress,
        firstAddress,
        secondAddress,
        error,
        isLoading,
    };
};

function handleError(err: any): string {
    if (err instanceof AxiosError) {
        if (err.response) {
            if (err.response.status == 429) {
                return 'Too many requests. Please try again.';
            }

            if (err.response.data && err.response.data.error) {
                return err.response.data.error;
            }

            let error = `Code ${err.response.status}`;

            if (err.response.statusText) {
                error =
                    'We are on technical maintenance. Please try again later.';
            }

            return error;
        }

        return err.message;
    }

    if (err instanceof Error) {
        return err.message;
    }

    return 'Unknown error. Please try again.';
}
