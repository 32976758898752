import { memo, RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { IWrapperProps } from '../../../shared/ui/SectionTitle/types';
import { ReactComponent as BurgerIcon } from '../assets/burger.svg';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { navItemsList } from './consts';

import s from './Nav.module.scss';

interface INavComponent extends IWrapperProps {
    howItWorkRef: RefObject<HTMLDivElement>;
    benefitsRef: RefObject<HTMLDivElement>;
    faqsRef: RefObject<HTMLDivElement>;
    contactsRef: RefObject<HTMLDivElement>;
}

const NavComponent = ({ rtlDirection, ...otherProps }: INavComponent) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [wasClick, setWasClick] = useState(false);

    const handleClick = () => {
        setWasClick(true);
        setIsOpen((prev) => !prev);
    };

    const handleClickAnchor = (ref: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (otherProps[ref].current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            otherProps[ref]?.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div
            className={cn(s.nav, {
                [s.open]: isOpen,
                [s.rtlDirection]: rtlDirection,
            })}
        >
            <button onClick={handleClick} className={s.btn}>
                <CloseIcon
                    className={cn(s.closeIcon, { [s.opened]: isOpen })}
                />
                <BurgerIcon
                    className={cn(s.burgerIcon, {
                        [s.closed]: !isOpen && wasClick,
                    })}
                />
            </button>
            <ul className={s.navMenu}>
                {navItemsList.map((item, i) => (
                    <li key={i} className={s.navItem}>
                        <a
                            onClick={() => handleClickAnchor(item.ref)}
                            className={s.navLink}
                        >
                            {t(item.title)}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const Nav = memo(NavComponent);
