export interface INavItem {
    ref: string;
    title: string;
}

export const navItemsList: INavItem[] = [
    { ref: 'howItWorkRef', title: 'How Does It Work?' },
    { ref: 'benefitsRef', title: 'Benefits' },
    { ref: 'faqsRef', title: 'FAQ' },
    { ref: 'contactsRef', title: 'Contacts' },
];
